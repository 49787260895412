import React from 'react';
import _ from 'lodash';
import { alert } from '@platform/utils/alert';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';

import { Button, Icon } from '@core/ui';

import { safeStringify, safeParse } from '@platform/utils/json';
import { dereferenceSchema, isSchemaViewerEmpty } from '@platform/utils/schemas';

import mergeAllOf from 'json-schema-merge-allof';

import MarkdownViewer from '../MarkdownViewer';
import ErrorMessage from '../ErrorMessage';

const isCombiner = prop => {
  return prop.anyOf || prop.oneOf ? true : false;
};

const pickValidations = prop => {
  const validations = {};
  if (prop.enum && prop.enum.join) {
    validations['Allowed Values'] = prop.enum.join(', ');
  } else if (prop.enum) {
    validations['Allowed Values'] = prop.enum;
  } else if (prop.items && !_.isEmpty(prop.items.enum)) {
    validations['Allowed Values'] = prop.items.enum.join(', ');
  }

  _.merge(
    validations,
    _.omit(
      prop,
      'title',
      'description',
      'type',
      'enum',
      'properties',
      'items',
      'additionalProperties',
      '$ref',
      '_active',
      '_isOpen',
      'required',
      'xml',
      'patternProperties',
      '__inheritedFrom',
      '__error'
    )
  );

  return validations;
};

const PropValidations = ({ className, prop }) => {
  if (!isCombiner(prop)) {
    const validations = pickValidations(prop);

    const elems = [];
    for (const k in validations) {
      if (!Object.prototype.hasOwnProperty.call(validations, k)) {
        continue;
      }

      let v = validations[k];

      let type = typeof v;

      if (k === 'default' && _.includes(['object', 'boolean'], type)) {
        v = safeStringify(v);

        type = typeof v;
      }

      if (type === 'object') {
        continue;
      } else if (type === 'boolean') {
        elems.push(
          <div key={k} className={className}>
            <b>{k}:</b> {v.toString()}
          </div>
        );
      } else {
        elems.push(
          <div key={k} className={className}>
            <b>{k}:</b> {v}
          </div>
        );
      }
    }

    if (elems.length) {
      return elems;
    }
  }

  return null;
};

const validationText = prop => {
  if (!isCombiner(prop)) {
    const validationCount = Object.keys(pickValidations(prop)).length;

    if (validationCount) {
      return `${validationCount} ${pluralize('validation', validationCount)}`;
    }
  }

  return '';
};

const getProps = ({ parsed = {} }) => {
  const target = parsed.items || parsed;

  let props = target.properties || {};
  if (target.patternProperties) {
    if (props) {
      props = _.merge(target.patternProperties, props);
    } else {
      props = target.patternProperties;
    }
  }

  return props;
};

const renderProp = ({
  schemas,
  level,
  parentName,
  rowElems,
  propName,
  prop,
  required,
  toggleExpandRow,
  expandedRows,
  defaultExpandedDepth,
  forApiDocs,
  hideInheritedFrom,
  jsonPath,
  hideRoot,
}) => {
  const position = rowElems.length;
  const name = propName;
  const rowKey = jsonPath;

  if (!prop) {
    rowElems.push(
      <div key={position} className={`text-negative py-2 JSV-row--${level}`}>
        Could not render prop. Is it valid? If it is a $ref, does the $ref exist?
      </div>
    );
    return rowElems;
  }

  let propType;
  let childPropType;
  let isBasic = false;
  let expandable = false;
  const expanded = _.has(expandedRows, rowKey)
    ? expandedRows[rowKey]
    : expandedRows.all || level <= defaultExpandedDepth;

  if (prop.items) {
    propType = prop.type;
    if (prop.items.anyOf) {
      childPropType = 'anyOf';
    } else if (prop.items.oneOf) {
      childPropType = 'oneOf';
    } else if (prop.items.type) {
      childPropType = prop.items.type;
    }

    propType = prop.type;
    isBasic = true;

    if (
      prop.items.properties ||
      prop.items.patternProperties ||
      prop.items.oneOf ||
      prop.items.anyOf
    ) {
      expandable = true;
    }
  } else if (prop.oneOf) {
    propType = 'oneOf';
    expandable = !_.isEmpty(prop.oneOf);
  } else if (prop.anyOf) {
    propType = 'anyOf';
    expandable = !_.isEmpty(prop.anyOf);
  } else {
    propType = prop.type;
    isBasic = prop.properties || prop.patternProperties || propType === 'object';

    if (prop.properties || prop.patternProperties) {
      expandable = true;
    }
  }

  if (jsonPath === 'root') expandable = false;

  let types = [];
  if (_.isString(propType)) {
    types = [propType];
  } else {
    types = propType;
  }

  let typeElems = [];
  if (!_.isEmpty(types)) {
    typeElems = types.reduce((acc, type, i) => {
      acc.push(
        <span key={i} className={`sl--${type}`}>
          {type === 'array' && childPropType && childPropType !== 'array'
            ? `array[${childPropType}]`
            : type}
        </span>
      );

      if (types[i + 1]) {
        acc.push(
          <span key={`${i}-sep`} className="c-muted">
            {' or '}
          </span>
        );
      }

      return acc;
    }, []);
  } else if (prop.$ref) {
    typeElems.push(
      <span key="prop-ref" className="sl--ref">
        {`{${prop.$ref}}`}
      </span>
    );
  } else if (prop.__error || isBasic) {
    typeElems.push(
      <span key="no-types" className="c-negative">
        {prop.__error || 'ERROR_NO_TYPE'}
      </span>
    );
  }

  let requiredElem;

  const vt = validationText(prop);
  let showVt = !expanded && vt;

  if (required || vt) {
    requiredElem = (
      <div>
        {showVt ? <span className="text-muted">{vt}</span> : null}
        {showVt && required ? <span className="text-muted"> + </span> : null}
        {required ? <span className="font-bold">required</span> : null}
      </div>
    );
  }

  const showInheritedFrom = !hideInheritedFrom && !_.isEmpty(prop.__inheritedFrom);

  if (!(hideRoot && jsonPath === 'root')) {
    rowElems.push(
      <div
        key={position}
        className={cn(`JSV-row JSV-row--${level} flex relative py-2`, {
          'cursor-pointer': vt || expandable,
          'is-expanded': expanded,
        })}
        onClick={() => {
          if (vt || expandable) {
            toggleExpandRow(rowKey, !expanded);
          }
        }}
      >
        {expandable ? (
          <div className="JSV-rowExpander w-4 -ml-6 flex justify-center mt-1">
            <Icon icon={expanded ? faCaretDown : faCaretRight} />
          </div>
        ) : null}

        <div className="flex-1">
          <div className="flex items-baseline">
            {name && name !== 'root' ? <div className="mr-3">{name}</div> : null}

            {!_.isEmpty(typeElems) && (
              <div
                className={cn('JSV-rowType', {
                  'JSV-namelessType': !name,
                })}
              >
                {typeElems}
              </div>
            )}
          </div>

          {!_.isEmpty(prop.description) ? (
            <MarkdownViewer className="text-muted text-sm" value={prop.description} />
          ) : null}
        </div>

        {requiredElem || showInheritedFrom || expanded ? (
          <div className="text-right text-sm pr-3 max-w-sm">
            {requiredElem}

            {showInheritedFrom ? (
              <div className="text-muted">{`$ref:${prop.__inheritedFrom.name}`}</div>
            ) : null}

            {expanded && <PropValidations className="text-muted" prop={prop} />}
          </div>
        ) : null}
      </div>
    );
  }

  const properties = getProps({ parsed: prop });
  const requiredElems = prop.items ? prop.items.required : prop.required;
  const commonProps = {
    schemas,
    rowElems,
    toggleExpandRow,
    expandedRows,
    defaultExpandedDepth,
    forApiDocs,
    parentName: name,
    props: properties,
    hideInheritedFrom,
    jsonPath,
    required: requiredElems || [],
  };

  if (expanded || jsonPath === 'root') {
    if (properties && Object.keys(properties).length) {
      rowElems = renderProps({
        ...commonProps,
        props: properties,
        level: level + 1,
      });
    } else if (prop.items) {
      if (prop.items.oneOf) {
        rowElems = renderCombiner({
          ...commonProps,
          props: prop.items.oneOf,
          level: level + 1,
          defaultType: prop.items.type,
        });
      } else if (prop.items.anyOf) {
        rowElems = renderCombiner({
          ...commonProps,
          props: prop.items.anyOf,
          level: level + 1,
          defaultType: prop.items.type,
        });
      }
    } else if (prop.oneOf) {
      rowElems = renderCombiner({
        ...commonProps,
        props: prop.oneOf,
        level: level + 1,
        defaultType: prop.type,
      });
    } else if (prop.anyOf) {
      rowElems = renderCombiner({
        ...commonProps,
        props: prop.anyOf,
        level: level + 1,
        defaultType: prop.type,
      });
    }
  }

  return rowElems;
};

const renderProps = ({
  schemas,
  level,
  parentName,
  rowElems,
  props,
  required,
  toggleExpandRow,
  expandedRows,
  defaultExpandedDepth,
  forApiDocs,
  hideInheritedFrom,
  jsonPath,
}) => {
  for (const h in props) {
    if (!Object.prototype.hasOwnProperty.call(props, h)) {
      continue;
    }

    rowElems = renderProp({
      schemas,
      level,
      parentName,
      rowElems,
      toggleExpandRow,
      expandedRows,
      defaultExpandedDepth,
      forApiDocs,
      propName: h,
      prop: props[h],
      required: _.includes(required, h),
      hideInheritedFrom,
      jsonPath: `${jsonPath}.${h}`,
    });
  }
  return rowElems;
};

const renderRowDivider = ({ key, level, text }) => {
  return (
    <div key={`${key}-d`} className={`JSV-divider--${level} h-10 flex items-center`}>
      <div className="c-muted pr-3 uppercase text-sm">{text}</div>
      <div className="h-px bg-grey-light flex-1 mr-4" />
    </div>
  );
};

const renderCombiner = ({
  schemas,
  level,
  parentName,
  defaultType,
  rowElems,
  props,
  toggleExpandRow,
  expandedRows,
  defaultExpandedDepth,
  forApiDocs,
  hideInheritedFrom,
  jsonPath,
}) => {
  for (const e in props) {
    if (!Object.prototype.hasOwnProperty.call(props, e)) {
      continue;
    }

    const elem = props[e];

    if (!_.has(elem, 'type') && defaultType) {
      _.set(elem, 'type', defaultType);
    }

    const key = `${parentName}-c-${level}-${e}`;

    const nextLevel = level === 0 && (elem.properties || elem.items) ? 1 : level;
    const commonProps = {
      schemas,
      parentName,
      rowElems,
      toggleExpandRow,
      expandedRows,
      defaultExpandedDepth,
      level: nextLevel,
      forApiDocs,
      hideInheritedFrom,
      jsonPath: `${jsonPath}.${e}`,
    };

    if (elem.properties) {
      if (!elem.type) {
        elem.type = 'object';
      }
      rowElems = renderProp({ ...commonProps, prop: elem });
    } else if (elem.items) {
      if (!elem.type) {
        elem.type = 'array';
      }
      rowElems = renderProp({ ...commonProps, prop: elem });
    } else {
      rowElems = renderSchema({
        ...commonProps,
        schema: elem,
        name: elem.properties || elem.items ? key : null,
      });
    }

    if (props[parseInt(e) + 1]) {
      rowElems.push(renderRowDivider({ key, level, text: 'or' }));
    }
  }

  return rowElems;
};

const renderSchema = ({
  schemas,
  schema,
  level,
  name,
  rowElems,
  toggleExpandRow,
  expandedRows,
  defaultExpandedDepth,
  forApiDocs,
  hideInheritedFrom,
  jsonPath,
  hideRoot,
}) => {
  let parsed = schema;

  if (!parsed) {
    return rowElems;
  }

  parsed = safeParse(parsed);
  if (_.isEmpty(parsed)) {
    return rowElems;
  }

  let nextLevel = level;
  const commonProps = {
    schemas,
    rowElems,
    toggleExpandRow,
    expandedRows,
    defaultExpandedDepth,
    forApiDocs,
    parentName: name,
    propName: name,
    required: _.includes(parsed.required || [], name),
    hideInheritedFrom,
    jsonPath,
    hideRoot,
  };

  if (parsed.properties) {
    const prop = {
      ...parsed,
      type: 'object',
      description: parsed.description,
    };

    if (!hideInheritedFrom && parsed.__inheritedFrom) {
      Object.assign(prop, _.pick(parsed, '__inheritedFrom'));
    }

    rowElems = renderProp({
      ...commonProps,
      level,
      prop,
    });
  } else if (parsed.items) {
    return renderProp({
      ...commonProps,
      level: nextLevel,
      prop: parsed,
    });
  } else if (parsed.oneOf) {
    return renderCombiner({
      ...commonProps,
      level: nextLevel,
      props: parsed.oneOf,
      defaultType: parsed.type,
    });
  } else if (parsed.anyOf) {
    return renderCombiner({
      ...commonProps,
      level: nextLevel,
      props: parsed.anyOf,
      defaultType: parsed.type,
    });
  } else if (parsed.type) {
    return renderProp({
      ...commonProps,
      level: nextLevel,
      prop: parsed,
    });
  }

  return rowElems;
};

class JsonSchemaViewer extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    schemas: PropTypes.object,
    schema: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    limitPropertyCount: PropTypes.number,
    hideRoot: PropTypes.bool,
    expanded: PropTypes.bool,

    emptyText: PropTypes.string,
    emptyClass: PropTypes.string,
  };

  state = {
    showExtra: false,
    expandedRows: {
      all: false,
    },
    showCopied: false,
  };

  render() {
    const {
      name,
      schema,
      dereferencedSchema,
      schemas = {},
      limitPropertyCount,
      hideRoot,
      expanded,
      defaultExpandedDepth = 1,
      emptyText,
      emptyClass = '',
      forApiDocs,
      hideInheritedFrom,
    } = this.props;

    const emptyElem = <div className="u-none c-muted">{emptyText || 'No schema defined.'}</div>;

    // an empty array or object is still a valid response, schema is ONLY really empty when a combiner type has no information
    if (isSchemaViewerEmpty(schema)) {
      return <div className={`${emptyClass}`}>{emptyElem}</div>;
    }

    let parsed = dereferencedSchema || schema;

    try {
      if (typeof parsed === 'string') {
        parsed = safeParse(parsed);
      }

      if (!dereferencedSchema || _.isEmpty(dereferencedSchema)) {
        parsed = dereferenceSchema(parsed, { definitions: schemas }, hideInheritedFrom);
      }
    } catch (e) {
      console.error('JsonSchemaViewer dereference error', e);
      return (
        <ErrorMessage
          error={`There is an error in your ${name} schema definition.`}
          className="m-3"
        />
      );
    }

    if (!parsed || !Object.keys(parsed).length) {
      return emptyElem;
    }

    let rowElems;

    const { expandedRows, showCopied } = this.state;
    expandedRows.all = expanded;

    try {
      // resolve all allOfs
      parsed = mergeAllOf(parsed, {
        resolvers: {
          defaultResolver: function(values, path, mergeSchemas, options) {
            // Handle merging unknown properties
            return _.merge(...values);
          },
        },
      });

      rowElems = renderSchema({
        schemas,
        expandedRows,
        defaultExpandedDepth,
        forApiDocs,
        schema: parsed,
        level: hideRoot && (parsed.type === 'object' || parsed.hasOwnProperty('allOf')) ? -1 : 0,
        name: 'root',
        rowElems: [],
        toggleExpandRow: this.toggleExpandRow,
        hideInheritedFrom,
        jsonPath: 'root',
        hideRoot,
      });
    } catch (e) {
      console.error('JSV:error', e);
      rowElems = <ErrorMessage error={e.message} className="m-3" />;
    }

    const { showExtra } = this.state;
    const propOverflowCount = rowElems.length - limitPropertyCount;

    if (limitPropertyCount) {
      if (!showExtra && propOverflowCount > 0) {
        rowElems = _.dropRight(rowElems, propOverflowCount);
      }
    }

    if (_.isEmpty(rowElems)) {
      return emptyElem;
    }

    return (
      <div className="JSV us-t u-schemaColors">
        {rowElems}

        {showExtra || propOverflowCount > 0 ? (
          <div
            className={cn('JSV-toggleExtra', { 'is-on': showExtra })}
            onClick={this.toggleShowExtra}
          >
            {showExtra ? 'collapse' : `...show ${propOverflowCount} more properties`}
          </div>
        ) : null}

        <div className="JSV-copyToClipboard">
          <Button
            disabled={showCopied}
            title="Copy to clipboard"
            className={cn('mt-1/2 mr-1/2', { 'pr-1': !showCopied })}
            icon={showCopied || faCopy}
            onClick={this.copy}
            transparent
          >
            {showCopied && 'Copied!'}
          </Button>
        </div>
      </div>
    );
  }

  toggleShowExtra = () => {
    const { showExtra } = this.state;
    this.setState({ showExtra: showExtra ? false : true });
  };

  toggleExpandRow = (rowKey, expanded) => {
    const { expandedRows } = this.state;
    const update = {};
    update[rowKey] = expanded;
    this.setState({ expandedRows: Object.assign({}, expandedRows, update) });
  };

  copy = () => {
    try {
      copy(JSON.stringify(this.props.schema, undefined, 2));
      this.setState({ showCopied: true });
      setTimeout(() => this.setState({ showCopied: false }), 2000);
    } catch (e) {
      alert.error(`Cannot copy to clipboard: ${e.message}`);
    }

  }
}

export default JsonSchemaViewer;

import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { languages, buildSnippet } from '@platform/utils/httpsnippet';
import Highlight from '../Highlight';
import FormDropdown from '../FormDropdown';

const CodeGenerator = props => {
  const { className, updateUi, ui, data = {} } = props;

  const selected = _.get(ui, 'selected') || _.head(languages);
  const libraries = selected.libraries;

  const { librarychoice, codechoice, mode } = selected;

  const snippet = buildSnippet(data, { codechoice, librarychoice });

  let code;

  if (_.has(snippet, 'error')) {
    code = <div className="c-muted p-2">{snippet.error}</div>;
  } else {
    code = (
      <div className="bg-white">
        <Highlight mode={mode}>{snippet}</Highlight>
      </div>
    );
  }

  return (
    <div className={cn('CodeGenerator', className)}>
      <div className="flex mb-6 items-center">
        <div className="pr-2 font-bold">Language</div>
        <FormDropdown
          className="flex-1"
          fluid
          value={selected.value}
          options={languages}
          onChange={(e, { value }) => {
            const selected = _.find(languages, function(o) {
              return o.value === value;
            });

            updateUi('set', 'selected', selected);
          }}
          selection
          search
        />
        {libraries
          ? [
              <div className="pl-6 font-bold" key="library-label">
                Library
              </div>,

              <FormDropdown
                className="flex-1 pl-2"
                key="library-dropdown"
                fluid
                value={_.get(selected, 'librarychoice') || _.get(selected, 'libraries.0.value')}
                options={libraries}
                onChange={(e, { value }) => {
                  const selected = _.find(libraries, function(o) {
                    return o.value === value;
                  });

                  updateUi('set', 'selected.librarychoice', selected.librarychoice);
                }}
                selection
                search
              />,
            ]
          : null}
      </div>

      {code}
    </div>
  );
};

export default inject(({ appStore }, { editorId }) => ({
  ...appStore.injectUi(`codegen-${editorId}`),
}))(observer(CodeGenerator));
